import React from 'react';
import Layout from '../layout';
import { Helmet } from 'react-helmet';





function About2() {
  return (
<Layout page='home'>
<Helmet>
        <title>Blossom Kids Hub | About</title>
      </Helmet>
   
    
      <div id="page-wrapper">
       
         <div class="jumbotron aboutus jumbotron-fluid">
            <div class="container" >
            
               <div class="jumbo-heading">
                  <h1>About us</h1>
              
                  <nav aria-label="breadcrumb">
                     <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">About us</li>
                     </ol>
                  </nav>
               
               </div>
             
            </div>
           
         </div>
        
       
         <div class="page container">
            <div class="row">
            
               <div class="col-lg-12 page-with-sidebar">
                  <div class="container">
                  
                     <div class="row">
                        <div class="col-lg-6 ">
                           <h2>Our Mission</h2>
                           <span class="h7 mt-2">We provide high quality daycare services</span>
                           <p class="mt-4 res-margin">To provide a safe, nurturing, and engaging environment that effectively supports the diverse needs of Children and families in our Community. We shall strive to give parents complete peace of mind while being seen as a shining example of what quality childcare should be.</p>
                           {/* <p>Nec elementum ipsum convall Maecenas at arcu risus scelerisque laoree.</p> */}
                           <h2 class="mt-5">Our Vision</h2>
                     <p class="mt-4 res-margin">To develop a safe developmentally, inclusive environment for toddlers, preschool, and kindergarten-age children. Our focus is to provide a stimulating early learning and childcare experience that promotes holistic development. We are committed to the families we serve, ensuring that every child’s milestone counts.</p>
                        </div>
                      
                        <div class="col-lg-6 ">
                        
                           <img class="img-fluid rounded" src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368471/blossom/About_mission_n_vission.jpg" alt=""/>
                        
                           <div class="ornament-rainbow" data-aos="zoom-out"></div>
                        </div>

                     </div>
                    
                     {/* <h3 class="mt-5">Our Vision</h3>
                     <p>To develop a safe developmentally, inclusive environment for toddlers, preschool, and kindergarten-age children. Our focus is to provide a stimulating early learning and childcare experience that promotes holistic development. We are committed to the families we serve, ensuring that every child’s milestone counts.</p> */}
                 
                     <div class="col-lg-12">
                     <div class="notepad mt-5"  data-aos="zoom-out">
                        <div class="row">
                           <div class="col-lg-4">
                              <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667918494/blossom/Head_mistress-01.jpg" class="img-fluid rounded rotate1" alt=""/>
                   
                              <div class="ornament-rainbow"  data-aos="zoom-out"></div>
                           </div>
                     
                           <div class="col-lg-8">
                              <h4 class="mt-3">Message from our Principal</h4>
                              <p class="res-margin">Our aim is to blend the Tanzanian curriculum with the Early Years Foundation Stage (EYFS) best practices in education.<br/>We have a team of professional teachers and caregivers who are dedicated to ensuring the safety and wellbeing of our pupils.<br/>Our new state of art day care and Preschool facility offers an enabling environment for the child to explore and discover, this helps the child to learn by first-hand experience.</p>
                              <a href="/contact"  class="btn btn-tertiary">Contact Us</a>
                           </div>
                       
                        </div>
                 
                     </div>
            
                  </div>
                   
                     {/* <h3 class="mt-5">Message from our Headmaster </h3>
                     <p>The Blossom Kids Team is comprised of a highly dedicated and enthusiastic group of Qualified early childhood educators and caregivers. We deliver our Services with Integrity, Honesty, Accountability, Teamwork, Caring, Commitment, and Excellence</p>
                    */}
                     {/* <div class="container-fluid mt-5">
                        <div class="bg-light block-padding">
          
          <h3 class="text-center pt-5">Our Team</h3>
       
             <div class="col-md-12 carousel-2items owl-carousel owl-theme">
        
                <div class="team-style2 col-lg-12 d-md-flex justify-content-between">
                   <div class="img-box my-auto">
                  
                      <a href="team-single.html">
                      <img src="img/team/team1.jpg" class="rounded-circle img-fluid"
                         alt=""/>
                      </a>
                   </div>
                   <div class="mx-4">
                
                      <a href="team-single.html">
                         <h5 class="mb-2">Laura Smith</h5>
                      </a>
                      <h6 class="mb-3">Teacher</h6>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id officiis hic
                         tenetur.
                      </p>
                   </div>
                
                   <ul class="social-icons bg-tertiary text-center">
                      <li> <a href="#"><i class="fab fa-facebook"></i></a></li>
                      <li> <a href="#"><i class="fab fa-twitter"></i></a></li>
                      <li> <a href="#"><i class="fab fa-linkedin"></i></a></li>
                   </ul>
                </div>
            
                <div class="team-style2 col-lg-12 d-md-flex justify-content-between">
                   <div class="img-box my-auto">
               
                      <a href="team-single.html">
                      <img src="img/team/team2.jpg" class="rounded-circle img-fluid"
                         alt=""/>
                      </a>
                   </div>
                   <div class="mx-4">
                  
                      <a href="team-single.html">
                         <h5 class="mb-2">John Doe</h5>
                      </a>
                      <h6 class="mb-3">Administrator</h6>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id officiis hic
                         tenetur.
                      </p>
                   </div>
              
                   <ul class="social-icons bg-tertiary text-center">
                      <li> <a href="#"><i class="fab fa-facebook"></i></a></li>
                      <li> <a href="#"><i class="fab fa-twitter"></i></a></li>
                      <li> <a href="#"><i class="fab fa-linkedin"></i></a></li>
                   </ul>
                </div>
             
                <div class="team-style2 col-lg-12 d-md-flex justify-content-between">
                   <div class="img-box my-auto">
                 
                      <a href="team-single.html">
                      <img src="img/team/team3.jpg" class="rounded-circle img-fluid"
                         alt=""/>
                      </a>
                   </div>
                   <div class="mx-4">
                 
                      <a href="team-single.html">
                         <h5 class="mb-2">Meghan Smith</h5>
                      </a>
                      <h6 class="mb-3">Assistant teacher</h6>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id officiis hic
                         tenetur.
                      </p>
                   </div>
             
                   <ul class="social-icons bg-tertiary text-center">
                      <li> <a href="#"><i class="fab fa-facebook"></i></a></li>
                      <li> <a href="#"><i class="fab fa-twitter"></i></a></li>
                      <li> <a href="#"><i class="fab fa-linkedin"></i></a></li>
                   </ul>
                </div>
           
                <div class="team-style2 col-lg-12 d-md-flex justify-content-between">
                   <div class="img-box my-auto">
             
                      <a href="team-single.html">
                      <img src="img/team/team4.jpg" class="rounded-circle img-fluid"
                         alt=""/>
                      </a>
                   </div>
                   <div class="mx-4">
                    
                      <a href="team-single.html">
                         <h5 class="mb-2">Mika Doe</h5>
                      </a>
                      <h6 class="mb-3">Teacher</h6>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id officiis hic
                         tenetur.
                      </p>
                   </div>
              
                   <ul class="social-icons bg-tertiary text-center">
                      <li> <a href="#"><i class="fab fa-facebook"></i></a></li>
                      <li> <a href="#"><i class="fab fa-twitter"></i></a></li>
                      <li> <a href="#"><i class="fab fa-linkedin"></i></a></li>
                   </ul>
                </div>
           
                <div class="team-style2 col-lg-12 d-md-flex justify-content-between">
                   <div class="img-box my-auto">
                    
                      <a href="team-single.html">
                      <img src="img/team/team5.jpg" class="rounded-circle img-fluid"
                         alt=""/>
                      </a>
                   </div>
                   <div class="mx-4">
                
                      <a href="team-single.html">
                         <h5 class="mb-2">Jillian Smith</h5>
                      </a>
                      <h6 class="mb-3">Assistant Teacher</h6>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id officiis hic
                         tenetur.
                      </p>
                   </div>
             
                   <ul class="social-icons bg-tertiary text-center">
                      <li> <a href="#"><i class="fab fa-facebook"></i></a></li>
                      <li> <a href="#"><i class="fab fa-twitter"></i></a></li>
                      <li> <a href="#"><i class="fab fa-linkedin"></i></a></li>
                   </ul>
                </div>
        
             </div>
      
                      </div> 
                     </div> */}
                    
                
                  
                     <div class="mt-5">
                    
                        <div class="enroll-calltoaction col-md-12 card bg-tertiary text-light no-bg-small">
                           <div class="text-center p-3">
                              <div class="col-lg-8 offset-lg-4">
                                 <h4>Join Blossom Kids Hub</h4>
                                 <p>We are Open to the Intake of children from the age of three months to five years. This includes Infants, Toddlers, and preschool children. Our Offices are Open from 08:00 am to 5:00 pm from Mondays to Fridays.</p>
                              
                                 <div class="text-center">
                                    <a href="/contact" class="btn btn-primary mt-2">Enroll today</a>
                                 </div>
                             
                              </div>
                          
                           </div>
                         
                        </div>
                             
                     </div>
                   
                  </div>
                 
               </div>
            
               {/* <div id="sidebar" class="h-50 col-lg-3 sticky-top">
              
                  <div class="widget-area notepad">
                     <h5 class="sidebar-header">Meet our Team</h5>
                    
                     <div class="widget2">
                        <div class="card">
                           <div class="card-img">
                           
                              <a href="team.html">
                              <img class="rounded card-img-top" src="img/about/aboutsidebar.jpg" alt="" />
                              </a>
                           </div>
                           <div class="card-body">
                             	
                              <a href="team.html" class="text-center">
                                 <p><strong>Over 30 Certified professionals</strong></p>
                              </a>
                           	
                              <a href="team.html" class="btn btn-secondary btn-block btn-sm">See More</a>
                           </div>
                         
                        </div>
                      
                     </div>
             
                  </div>
               
                  <div class="widget-area notepad">
                     <h5 class="sidebar-header">Our Mission</h5>
                     <p>Aliquam erat volutpat In id fermentum augue Mae cenas at arcu risus. Donec com modo sodales ex.</p>
                  </div>
                
                  <div class="widget-area notepad">
                     <h5 class="sidebar-header">Follow us</h5>
                     <div class="contact-icon-info">
                        <ul class="social-media text-center">
                        
                           <li><a href="#"><i class="fab fa-facebook-square"></i></a></li>
                           <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                           <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                           <li><a href="#"><i class="fab fa-pinterest"></i></a></li>
                        </ul>
                     </div>
                
                  </div>
              
               </div> */}
            
            </div>
         
         </div>
       
      </div>

   
    
   </Layout>
  );
}

export default About2;