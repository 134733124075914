import React from 'react';
import Layout from '../layout';
import { Helmet } from 'react-helmet';





function Homepage() {
  return (
<Layout page='home'>
<Helmet>
        <title>Blossom Kids Hub | Home</title>
      </Helmet>
      <div id="page-wrapper">
         <div class="container-fluid p-0">
        
            <div id="slider" class="parallax-slider" style={{width: "1200px", margin: "0 auto", marginBottom: "0px"}}>
            
               <div class="ls-slide" data-ls="duration:4000; transition2d:7;">
            
                  <img src="img/slider/parallaxslider/slide1.jpg" class="ls-bg" alt="" />
            
                  <img  src="img/slider/parallaxslider/clouds.png" class="ls-l" alt="" style={{top: "56px", left: "-100px"}} data-ls="parallax:true; parallaxlevel:-5;"/>
        
                  <img  src="img/slider/parallaxslider/butterflies.png" class="ls-l" alt="" style={{top: "16px", left: "0px"}} data-ls=" parallax:true; parallaxlevel:4;"/>
           
                  <img  src="img/slider/parallaxslider/sun.png" class="ls-l" alt="" style={{top: "-190px", left: "650px"}} data-ls="parallax:true; parallaxlevel:-3;"/>
                
                  <img  src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368474/blossom/Home_page_banner.png" class="ls-l" alt="" style={{top: "166px",left: "520px"}} data-ls="offsetxin:10; offsetyin:120; durationin:1100; rotatein:5; transformoriginin:59.3% 80.3% 0; offsetxout:-80; durationout:400; parallax:true; parallaxlevel:10;"/>
            
                  <div class="ls-l header-wrapper" data-ls="offsetyin:150; durationin:700; delayin:200; easingin:easeOutQuint; rotatexin:20; scalexin:1.4; offsetyout:600; durationout:400;">
                     <div class="header-text">
                        <span>Welcome to</span> 
                        <h1> Blossom Kids Hub</h1>
                      
                        <div class="hidden-small">
                           <p class="header-p">Where Every Milestone Counts!</p>
                           <a class="btn btn-secondary" href="/contact">Contact us</a>
                        </div>
                   
                     </div>
                   
                  </div>
             
               </div>
             
            </div>
      
            {/* <svg version="1.1" id="divider"  class="slider-divider" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 1440 126" preserveAspectRatio="none slice" xml:space="preserve">
               <path class="st0" d="M685.6,38.8C418.7-11.1,170.2,9.9,0,30v96h1440V30C1252.7,52.2,1010,99.4,685.6,38.8z"/>
            </svg> */}
         </div>
     
         <div class="container">
          
            <section id="intro-cards"  class="row pb-0">
           
          
             <div class="col-lg-4 col-md-4 col-sm-12">
                  <div class="card card-flip home-card">
                  
                     <div class="card bg-secondary text-light ">
                        <div class="p-5">
                           <h5 class="card-title text-light">Licensed Day Care and Preschool</h5>
                           <p class="card-text">
                           With fun and engaging experience for your child's Growth and Development.
                           </p>
                        
                           <a href="/contact" class="btn d-lg-none">Contact us</a>
                        </div>
                      
                        
                        <img class="card-img" src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368471/blossom/Blue_card.jpg" alt=""/>
                     </div>
                			
                     <div class="card bg-secondary text-light card-back">
                        <div class="card-body d-flex justify-content-center align-items-center">
                           <div class="p-4">
                              <h5 class="card-title text-light">Lifestyle Hub</h5>
                              <p class="card-text">With our Programs and engaging activities, We help children to develop a sense of security, safety, and Critical Social Skills in the early stages of growth.
                              </p>
                              
                              <a href="/contact" class="btn">contact us</a>
                           </div>
                          
                        </div>
                    
                     </div>
                   
                  </div>
             
               </div>
              
               <div class="col-lg-4  col-md-4 col-sm-12">
                  <div class="card card-flip home-card">
                    
                     <div class="card bg-primary text-light">
                        <div class="pt-5 pl-5 pr-5">
                           <h5 class="card-title text-light">Safety, Security, and Fun</h5>
                           <p class="card-text">
                           Under Supervised conditions, Children will study, play and interact with each other.
                           </p>
                        
                           <a href="/services" class="btn d-lg-none">Our Services</a>
                        </div>
               
                        
                        <img class="card-img" src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368477/blossom/red_card.jpg" alt=""/>
                     </div>
                   			
                     <div class="card bg-primary text-light card-back">
                        <div class="card-body d-flex justify-content-center align-items-center">
                           <div class="p-4">
                              <h5 class="card-title text-light">Nurturing Environment</h5>
                              <p class="card-text">Classrooms, Healthy meals, Play Grounds, Resting Beds, and Proper Supervision from Our Professionals are all your kid needs for growth and development.

                              </p>
                              
                              <a href="/services" class="btn">Our Programs</a>
                           </div>
               
                        </div>
                       
                     </div>
             
                  </div>
                 
               </div>
              
               <div class="col-lg-4  col-md-4 col-sm-12">
                  <div class="card card-flip home-card">
                  
                     <div class="card bg-tertiary text-light">
                        <div class="p-5">
                           <h5 class="card-title text-light">Talented Staff</h5>
                           <p class="card-text">
                           Will Support and Guide your Child through Every Milestone.
                           </p>
                         
                           <a href="/about" class="btn d-lg-none">Our Team</a>
                        </div>
            
                        
                        <img class="card-img" src="https://res.cloudinary.com/dedfrilse/image/upload/v1668008902/blossom/yellow.jpg" alt=""/>
                     </div>
                   			
                     <div class="card bg-tertiary text-light card-back">
                        <div class="card-body d-flex justify-content-center align-items-center">
                           <div class="p-4">
                              <h5 class="card-title text-light">Qualified Caregivers</h5>
                              <p class="card-text">
Our Qualified teachers and Caregivers are trained to support and model your child’s lifestyle for a long-lasting impact on your child’s growth and development.
                              </p>
                              
                              <a href="/about" class="btn">Our Team</a>
                           </div>
                  
                        </div>
                     
                     </div>
        
                  </div>
               
               </div>
          
       
            </section>
          
         </div>
        
       
         <section id="about-home" class="container-fluid pb-0">
            <div class="container">
            
               <div class="section-heading text-center">
                  <h2>About Us</h2>
                  <p class="subtitle">Get to know us</p>
               </div>
             
               <div class="row">
                  <div class="col-lg-7 ">
                     <h3>Our Promise</h3>
                     <p class="mt-4 res-margin">We Promise to provide the best Care for every Child in our hands, helping to give them the best
start in life and providing peace of mind to Parents. It means that you can concentrate on your day-to-day activities knowing that your child is safe and being well looked after.</p>
                     {/* <p>Nec elementum ipsum convall. Aliquam erat volutpat In id fermentum augue, ut pellentesque leo. Maecen Etiam rhoncus leo a dolor placerat, nec elementum ipsum convall Maecenas at arcu risus scelerisque laoree.</p> */}
                     <a href="/contact" class="btn btn-secondary ">Contact us</a>
                  </div>
               
                  <div class="col-lg-5 res-margin">
                     
                     <img class="img-fluid rounded" src="https://res.cloudinary.com/dedfrilse/image/upload/v1668009044/blossom/ourpromisephoto.jpg" alt=""/>
                   
                     <div class="ornament-rainbow" data-aos="zoom-out"></div>
                  </div>
            
               </div>
               
               {/* <h3 class="mt-5 text-center">What Parents say:</h3>
               <div class="row">
                 
                  <div class="carousel-2items owl-carousel owl-theme col-md-12">
                   
                     <div class="testimonial">
                        <div class="content">
                           <p class="description">
                              Scelerisque laoreet nibh hendrerit id. In aliquet magna nec lobortis maximus. Etiam rhoncus leo a dolor placerat, nec elementum ipsum convall.                        
                           </p>
                        </div>
                      
                        <div class="testimonial-pic">
                           <img src="https://www.ingridkuhn.com/themes/abctots/img/team/team1.jpg" class="img-fluid" alt=""/>
                        </div>
                      
                        <div class="testimonial-review">
                           <h5 class="testimonial-title">Lucianna Smith</h5>
                           <span class="post">Teacher</span>
                        </div>
                       
                     </div>
                   
                     <div class="testimonial">
                        <div class="content">
                           <p class="description">
                              Aliquam erat volutpat In id fermentum augue, ut pellentesque leo. Maecenas at arcu risus. Donec commodo sodales ex, scelerisque laoreet nibh hendrerit id. In aliquet magna nec lobortis maximus. Etiam rhoncus leo a dolor placerat, nec elementum ipsum convall.                        
                           </p>
                        </div>
                      
                        <div class="testimonial-pic">
                           <img src="https://www.ingridkuhn.com/themes/abctots/img/team/team2.jpg" class="img-fluid" alt=""/>
                        </div>
                      
                        <div class="testimonial-review">
                           <h5 class="testimonial-title">John Sadana</h5>
                           <span class="post">Doctor</span>
                        </div>
                       
                     </div>
                   
                     <div class="testimonial">
                        <div class="content">
                           <p class="description">
                              Aliquam erat volutpat In id fermentum augue, ut pellentesque leo. Maecenas at arcu risus. Donec commodo sodales ex, scelerisque laoreet nibh hendrerit id. In aliquet magna nec lobortis maximus. Etiam rhoncus leo a dolor placerat, nec elementum ipsum convall.                        
                           </p>
                        </div>
                      
                        <div class="testimonial-pic">
                           <img src="https://www.ingridkuhn.com/themes/abctots/img/team/team3.jpg" class="img-fluid" alt=""/>
                        </div>
                      
                        <div class="testimonial-review">
                           <h5 class="testimonial-title">Jane Janeth</h5>
                           <span class="post">Librarian</span>
                        </div>
                      
                     </div>
                    
                  </div>
                    
               </div> */}
              
            </div>
            
        
            <img src="img/ornaments/whale.png" class="floating-whale" alt=""/>
          
            <div class="waveHorizontals">
               <div id="waveHorizontal1" class="waveHorizontal"></div>
               <div id="waveHorizontal2" class="waveHorizontal"></div>
               <div id="waveHorizontal3" class="waveHorizontal"></div>
            </div>
           
            <div class="sea"></div>
          
         </section>
       
         <section id="features" class="bg-secondary text-light">
            <div class="container">
            
               <div class="section-heading text-center">
                  <h2>Why Choose Us?</h2>
                  <p class="subtitle">Benefits for you</p>
               </div>
             
               <div class="row ">
                  <div class="col-lg-6">
                     <div class="row ">
                        <div class="col-md-6 col-lg-6">
                         
                           <div class="feature-with-icon">
                              <div class="icon-features">
                              
                                 <i class="flaticon-maternity text-primary"></i>
                              </div>
                              <h5>Safe Enviroment</h5>
                              <p>Our Nurturing Environment ensures the Safety and Security of your child</p>
                           </div>
                         
                           <div class="feature-with-icon mt-5">
                              <div class="icon-features">
                             
                                 <i class="flaticon-open-book-1 text-primary"></i>
                              </div>
                              <h5>Educational activities</h5>
                              <p>We have Sufficient classrooms, equipment, and proven programs for studying</p>
                           </div>
                         
                        </div>
                        <div class="col-md-6 col-lg-6">
                         
                           <div class="feature-with-icon">
                              <div class="icon-features">
                              
                                 <i class="flaticon-classroom-1 text-primary"></i>
                              </div>
                              <h5>Qualified teachers</h5>
                              <p>Well-trained and Certified to nurture the best lifestyle for children
</p>
                           </div>
                         
                           <div class="feature-with-icon mt-5">
                              <div class="icon-features">
                               
                                 <i class="flaticon-baby-boy text-primary"></i>
                              </div>
                              <h5>Infant care</h5>
                              <p>Our Qualified caregivers ensure intensive care for infants and toddlers.</p>
                           </div>
                        
                        </div>
                       
                     </div>
                  
                  </div>
                
                  <div class="col-lg-6 features-bg">
                     
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667388686/blossom/why.png" alt="" class="img-fluid"  data-aos="zoom-out"   data-aos-delay="300"/>
                  </div>
                
               </div>
             
            </div>
         
         </section>
         <section id="services-home" class="container-fluid">
            <div class="container pb-5">
             
               <div class="section-heading text-center">
                  <h2>Our Programs</h2>
                  <p class="subtitle">what we offer</p>
               </div>
            
               <div class="row vertical-tabs">
                     <div class="col-lg-12">
          
                        <div class="tabs-with-icon">
                           <nav>
                              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                 <a class="nav-item nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1"><i class="flaticon-abc-block"></i>Daycare</a>
                                 <a class="nav-item nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" ><i class="flaticon-kids-1"></i>Kindergarten</a>
                                 <a class="nav-item nav-link" id="tab3-tab" data-toggle="tab" href="#tab3" ><i class="flaticon-smiling-baby"></i>Infant care</a>
                                 <a class="nav-item nav-link" id="tab4-tab" data-toggle="tab" href="#tab4"><i class="flaticon-blackboard"></i>Garden</a>
                                 <a class="nav-item nav-link" id="tab5-tab" data-toggle="tab" href="#tab5" ><i class="flaticon-kids"></i>Special Services</a>
                              </div>
                           </nav>
                  
                           <div class="tab-content block-padding bg-light" id="nav-tabContent">
                              <div class="tab-pane active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                               
                                 <div class="row">
                                    <div class="col-lg-6">
                              
                                       <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667389175/blossom/Our_services_Daycare.jpg" alt="" class="rounded img-fluid"/>
                                      
                                       <div class="ornament-rainbow" data-aos="fade-right"></div>
                                    </div>
                           
                                    <div class="col-lg-6">
                                       <h3>Daycare</h3>
                                       <p>Our daycare programs intake kids from the age of 6 months to 5 Years. Our Nurturing Environment and Professionals will offer Personal Assistance to every child and help with the Development of Hobbies, Creative Skills and Guide everything for</p>
                                       <ul class="custom pl-0">
                                          <li>Infants (6 months to 1 year )</li>
                                          <li>Toddlers (2 years to 3 years)</li>
                                          <li>Preschool kids (3 years to 5 years)</li>
                                       </ul>
                                     
                                       <a href="/gallery" class="btn btn-secondary mt-4">See More</a>
                                    </div>
                       
                                 </div>
                                
                              </div>
                        
                              <div class="tab-pane" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                                 <div class="row">
                                    <div class="col-lg-6">
                                       <h3>Kindergarten</h3>
                                       <p>Our kindergarten and preschool program is the perfect way to support your child’s school readiness in an exciting, engaging way. Our educational approach is based on studying, playing, singing, practical activities such as drawing, and social interaction</p>
                                       {/* <ul class="custom pl-0">
                                          <li>Over 30 Qualified professionals</li>
                                          <li>We offer you our quality services since 2002</li>
                                          <li>Educational activities in our daily plan</li>
                                       </ul>
                               
                                       <a href="/services-single" class="btn btn-secondary mt-4">Read More</a> */}
                                    </div>
                                 
                                    <div class="col-lg-6 res-margin">
                               
                                       <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368476/blossom/Our_programs_kindergarten.jpg" alt="" class="rounded img-fluid"/>
                                     
                                       <div class="ornament-stars" data-aos="fade-right"></div>
                                    </div>
                                 
                                 </div>
                               
                              </div>
                           
                              <div class="tab-pane" id="tab3" role="tabpanel" aria-labelledby="tab3-tab">
                                 <div class="row">
                                    <div class="col-lg-6">
                         
                                       <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368476/blossom/Our_programs_infant_care.jpg" alt="" class="rounded img-fluid"/>
                               
                                       <div class="ornament-bubbles" data-aos="fade-right"></div>
                                    </div>
                                  
                                    <div class="col-lg-6">
                                       <h3>Infant Care</h3>
                                       <p>Blossom Kids Hub will provide your infant with high-quality care services from our professional caregivers. Infants need intense care and so, we are the best choice for that.</p>
                                       {/* <ul class="custom pl-0">
                                          <li>Over 30 Qualified professionals</li>
                                          <li>We offer you our quality services since 2002</li>
                                          <li>Educational activities in our daily plan</li>
                                       </ul>
                                    
                                       <a href="/services-single" class="btn btn-secondary mt-4">Read More</a> */}
                                    </div>
                                  
                                 </div>
                        
                              </div>
                           
                              <div class="tab-pane" id="tab4" role="tabpanel" aria-labelledby="tab4-tab">
                                 <div class="row">
                                    <div class="col-lg-6">
                                       <h3>Garden</h3>
                                       <p>Garden activities will nourish Child’s curiosity with Wonder. Garden activities will also provide Fun games, Refreshments, Resting beds, and Healthy Meals.</p>
                                       {/* <ul class="custom pl-0">
                                          <li>Over 30 Qualified professionals</li>
                                          <li>We offer you our quality services since 2002</li>
                                          <li>Educational activities in our daily plan</li>
                                       </ul>
                                   
                                       <a href="services-single.html" class="btn btn-secondary mt-4">Read More</a> */}
                                    </div>
                                 
                                    <div class="col-lg-6 res-margin">
                            
                                       <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368476/blossom/Our_programs_garden.jpg" alt="" class="rounded img-fluid"/>
                                     
                                       <div class="ornament-stars" data-aos="fade-right"></div>
                                    </div>
                                   
                                 </div>
                               
                              </div>
                        
                              <div class="tab-pane" id="tab5" role="tabpanel" aria-labelledby="tab5-tab">
                                 <div class="row">
                                    <div class="col-lg-6">
                                     
                                       <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368477/blossom/Our_programs_special_services.jpg" alt="" class="rounded img-fluid"/>
                                 
                                       <div class="ornament-rainbow" data-aos="fade-right"></div>
                                    </div>
                                 
                                    <div class="col-lg-6">
                                       <h3>Special Services</h3>
                                       <p>Our Special Services include Camps and Vacations which are very important for kids to develop critical social, mental, and emotional skills.</p>
                                       {/* <ul class="custom pl-0">
                                          <li>Over 30 Qualified professionals</li>
                                          <li>We offer you our quality services since 2002</li>
                                          <li>Educational activities in our daily plan</li>
                                       </ul>
                                     
                                       <a href="services-single.html" class="btn btn-secondary mt-4">Read More</a> */}
                                    </div>
                                   
                                 </div>
                               
                              </div>
                            
                           </div>
                       
                        </div>
                    
                     </div>
                    
                  </div>
            
            </div>
         
         </section>
       
         {/* <section id="counter-section" class="container-fluid counter-calltoaction bg-fixed overlay"  data-100-bottom="background-position: 50% 100px;"
            data-top-bottom="background-position: 50% -100px;" >
            <div id="counter" class="container">
               <div  class="row col-lg-10 offset-lg-1">
                 
                  <div class="col-xl-4 col-md-4">
                     <div class="counter">
                        <div class="counter-wrapper bg-primary">
                           <i class="counter-icon flaticon-teacher"></i>
                        
                           <div class="counter-value" data-count="30">0</div>
                           <h3 class="title">Professionals</h3>
                        </div>
                     </div>
                
                  </div>
            
                  <div class="col-xl-4 col-md-4">
                     <div class="counter">
                        <div class="counter-wrapper bg-secondary">
                           <i class="counter-icon  flaticon-family"></i>
                         
                           <div class="counter-value" data-count="74">0</div>
                           <h3 class="title">Happy parents</h3>
                        </div>
                     </div>
                  
                  </div>
             
                  <div class="col-xl-4 col-md-4">
                     <div class="counter">
                        <div class="counter-wrapper bg-tertiary">
                           <i class="counter-icon flaticon-children"></i>
                        
                           <div class="counter-value" data-count="104">0</div>
                           <h3 class="title">Students</h3>
                        </div>
                     </div>
                   
                  </div>
                
               </div>
            
            </div>
         
         </section> */}
       
         {/* <section id="team-home" class="container">
           
            <div class="section-heading text-center">
               <h2>Our Team</h2>
               <p class="subtitle">Qualified Professionals</p>
            </div>
         
            <div class="row">
               <div class="col-lg-7">
                  <h3>Meet our Talented Team</h3>
                  <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                  <ul class="checkmark pl-0 font-weight-bold">
                     <li>Over 30 Qualified professionals</li>
                     <li>We offer you our quality services since 2002</li>
                     <li>Fun and educational activities in our daily plan</li>
                  </ul>
                
               </div>
             
               <div class="col-lg-5 res-margin">
                  <img src="https://www.ingridkuhn.com/themes/abctots/img/team/team-home.jpg" alt="" class="img-fluid blob2"/>
                
                  <div class="ornament-stars" data-aos="fade-down"></div>
               </div>
            
               <div class="col-lg-12">
               
                  <div class="carousel-4items owl-carousel owl-theme mt-5">
                  
                     <div class="col-md-12 team-style1 notepad">
                        <div class="team_img">
                           <a href="team-single.html">
                           <img src="https://www.ingridkuhn.com/themes/abctots/img/logo.png" class="img-fluid" alt=""/>
                           </a>
                         
                           <ul class="social">
                              <li><a href="#"><i class="fa fa-envelope"></i></a></li>
                              <li><a href="#"><i class="fab fa-facebook"></i></a></li>
                              <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                              <li><a href="#"><i class="fab fa-linkedin"></i></a></li>
                           </ul>
                        </div>
                       
                        <div class="team-content">
                           <a href="team-single.html">
                              <h5 class="title">Laura Smith</h5>
                           </a>
                           <span class="post">Teacher</span>
                           <p>Id fermentum augue, ut pellen tesque leo nas. Maecenas at arcu risus Donec com modo.</p>
                        </div>
                      
                     </div>
                   
                     <div class="col-md-12 team-style1 notepad">
                        <div class="team_img">
                           <a href="team-single.html">
                           <img src="https://www.ingridkuhn.com/themes/abctots/img/logo.png" class="img-fluid" alt=""/>
                           </a>
                        
                           <ul class="social">
                              <li><a href="#"><i class="fa fa-envelope"></i></a></li>
                              <li><a href="#"><i class="fab fa-facebook"></i></a></li>
                              <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                              <li><a href="#"><i class="fab fa-linkedin"></i></a></li>
                           </ul>
                        </div>
                     
                        <div class="team-content">
                           <a href="team-single.html">
                              <h5 class="title">John Doe</h5>
                           </a>
                           <span class="post">Administrator</span>
                           <p>Id fermentum augue, ut pellen tesque leo nas. Maecenas at arcu risus Donec com modo.</p>
                        </div>
                     
                     </div>
                   
                     <div class="col-md-12 team-style1 notepad">
                        <div class="team_img">
                           <a href="team-single.html">
                           <img src="https://www.ingridkuhn.com/themes/abctots/img/logo.png" class="img-fluid" alt=""/>
                           </a>
                         
                           <ul class="social">
                              <li><a href="#"><i class="fa fa-envelope"></i></a></li>
                              <li><a href="#"><i class="fab fa-facebook"></i></a></li>
                              <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                              <li><a href="#"><i class="fab fa-linkedin"></i></a></li>
                           </ul>
                        </div>
                     
                        <div class="team-content">
                           <a href="team-single.html">
                              <h5 class="title">Meghan Smith</h5>
                           </a>
                           <span class="post">Assitant Teacher</span>
                           <p>Id fermentum augue, ut pellen tesque leo nas. Maecenas at arcu risus Donec com modo.</p>
                        </div>
                     
                     </div>
                    
                     <div class="col-md-12 team-style1 notepad">
                        <div class="team_img">
                           <a href="team-single.html">
                           <img src="https://www.ingridkuhn.com/themes/abctots/img/logo.png" class="img-fluid" alt=""/>
                           </a>
                          
                           <ul class="social">
                              <li><a href="#"><i class="fa fa-envelope"></i></a></li>
                              <li><a href="#"><i class="fab fa-facebook"></i></a></li>
                              <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                              <li><a href="#"><i class="fab fa-linkedin"></i></a></li>
                           </ul>
                        </div>
                       
                        <div class="team-content">
                           <a href="team-single.html">
                              <h5 class="title">Mika Doe</h5>
                           </a>
                           <span class="post">Teacher</span>
                           <p>Id fermentum augue, ut pellen tesque leo nas. Maecenas at arcu risus Donec com modo.</p>
                        </div>
                       
                     </div>
                   
                     <div class="col-md-12 team-style1 notepad">
                        <div class="team_img">
                           <a href="team-single.html">
                           <img src="img/team/team5.jpg" class="img-fluid" alt=""/>
                           </a>
                          
                           <ul class="social">
                              <li><a href="#"><i class="fa fa-envelope"></i></a></li>
                              <li><a href="#"><i class="fab fa-facebook"></i></a></li>
                              <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                              <li><a href="#"><i class="fab fa-linkedin"></i></a></li>
                           </ul>
                        </div>
                      
                        <div class="team-content">
                           <a href="team-single.html">
                              <h5 class="title">Jillian Smith</h5>
                           </a>
                           <span class="post">Asssitant Teacher</span>
                           <p>Id fermentum augue, ut pellen tesque leo nas. Maecenas at arcu risus Donec com modo.</p>
                        </div>
                       
                     </div>
                   
                  </div>
                
               </div>
            
            </div>
          
         </section> */}
       
         <section id="gallery-home" class="container-fluid bg-tertiary no-bg-sm">
            <div class="container">
             
               <div class="section-heading text-center text-light">
                  <h2>Gallery</h2>
                  <p class="subtitle">Our facilities</p>
               </div>
              
               <div class="container magnific-popup mt-5">
               
               <div class="row">
                  
                  <div class="col-lg-3">
                     <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1667368472/blossom/Gallery_0001_693A5639.jpg" title="You can add caption to pictures.">
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368472/blossom/Gallery_0001_693A5639.jpg" class="blob img-fluid" alt=""/>
                     </a>
                  </div>
                  
                  <div class="col-lg-3">
                     <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1667368472/blossom/Gallery_0002_693A5716.jpg" title="You can add caption to pictures.">
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368472/blossom/Gallery_0002_693A5716.jpg" class="blob2 img-fluid" alt=""/>
                     </a>
                  </div>
                  
                  <div class="col-lg-3">
                     <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1667368472/blossom/Gallery_0003_693A5819.jpg" title="You can add caption to pictures.">
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368472/blossom/Gallery_0003_693A5819.jpg" class="blob img-fluid" alt=""/>
                     </a>
                  </div>
                  
                  <div class="col-lg-3">
                     <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1667368473/blossom/Gallery_0004_693A5962.jpg" title="You can add caption to pictures.">
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368473/blossom/Gallery_0004_693A5962.jpg" class="blob2 img-fluid" alt=""/>
                     </a>
                  </div>
               </div>
               
               
               <div class="row mt-4">
                  
                  <div class="col-lg-3">
                     <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1667368472/blossom/Gallery_0005_693A6091.jpg" title="You can add caption to pictures.">
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368472/blossom/Gallery_0005_693A6091.jpg" class="blob2 img-fluid" alt=""/>
                     </a>
                  </div>
                  
                  <div class="col-lg-3">
                     <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1667388686/blossom/gal.jpg" title="You can add caption to pictures.">
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667388686/blossom/gal.jpg" class="blob img-fluid" alt=""/>
                     </a>
                  </div>
                  
                  <div class="col-lg-3">
                     <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1667368472/blossom/Gallery_0007_693A5992.jpg" title="You can add caption to pictures.">
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368472/blossom/Gallery_0007_693A5992.jpg" class="blob2 img-fluid" alt=""/>
                     </a>
                  </div>
                  
                  <div class="col-lg-3">
                     <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1667368473/blossom/Gallery_0008_693A6200.jpg" title="You can add caption to pictures.">
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368473/blossom/Gallery_0008_693A6200.jpg" class="blob img-fluid" alt=""/>
                     </a>
                  </div>
               </div>
               
               
               <div class="row mt-4">
                  
                  <div class="col-lg-3">
                     <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1667368473/blossom/Gallery_0009_693A6182.jpg" title="You can add caption to pictures.">
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368473/blossom/Gallery_0009_693A6182.jpg" class="blob img-fluid" alt=""/>
                     </a>
                  </div>
                
                  <div class="col-lg-3">
                     <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1667368473/blossom/Gallery_0010_693A6102.jpg" title="You can add caption to pictures.">
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368473/blossom/Gallery_0010_693A6102.jpg" class="blob2 img-fluid" alt=""/>
                     </a>
                  </div>
              
                  <div class="col-lg-3">
                     <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1667368473/blossom/Gallery_0011_Copyof693A5573.jpg" title="You can add caption to pictures.">
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368473/blossom/Gallery_0011_Copyof693A5573.jpg" class="blob img-fluid" alt=""/>
                     </a>
                  </div>
        
                  <div class="col-lg-3">
                     <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1667368472/blossom/Gallery_0000_693A5964.jpg" title="You can add caption to pictures.">
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368472/blossom/Gallery_0000_693A5964.jpg" class="blob2 img-fluid" alt=""/>
                     </a>
                  </div>
               </div>
            
            </div>
                
            </div>
            
         </section>
         
         {/* <section id="blogprev-home" data-100-bottom="background-position: 0% 120%;"
            data-top-bottom="background-position: 0% 100%;">
            <div class="container">
              
               <div class="section-heading text-center">
                  <h2>Latest Blog Posts</h2>
                  <p class="subtitle">Our Updates</p>
               </div>
               
               <div class="carousel-3items owl-carousel owl-theme mt-0">
                  
                  <div class="blog-box">
                     
                     <a href="blog-single.html">
                        <div class="image"><img src="	https://www.ingridkuhn.com/themes/abctots/img/blog/blogstyle2-1.jpg" alt=""/></div>
                     </a>
                     
                     <div class="blog-box-caption">
                        
                        <div class="date"><span class="day">12</span><span class="month">May</span></div>
                        <a href="blog-single.html">
                           <h4>Helping Your Child with Socialization</h4>
                        </a>
                      
                        <p>
                           Donec commodo sodales ex, scelerisque laoreet nibh hendrerit id. In aliquet magna nec lobortis...
                        </p>
                     </div>
                     
                     <div class="blog-box-footer">
                        <div class="author">Posted by<a href="#"><i class="fas fa-user"></i>Lauren Smith</a></div>
                        <div class="comments"><a href="blog-single.html"><i class="fas fa-comment"></i>23</a></div>
                        	 
                        <div class="text-center col-md-12">
                           <a href="blog-single.html" class="btn btn-primary ">Read more</a>
                        </div>
                     </div>
                   
                  </div>
                  
                  <div class="blog-box">
                     
                     <a href="blog-single.html">
                        <div class="image"><img src="	https://www.ingridkuhn.com/themes/abctots/img/blog/blogstyle2-2.jpg" alt=""/></div>
                     </a>
                     
                     <div class="blog-box-caption">
                        
                        <div class="date"><span class="day">28</span><span class="month">June</span></div>
                        <a href="blog-single.html">
                           <h4>Our Healthy meals that toddlers love</h4>
                        </a>
                      
                        <p>
                           Donec commodo sodales ex, scelerisque laoreet nibh hendrerit id. In aliquet magna nec lobortis...
                        </p>
                     </div>
                  
                     <div class="blog-box-footer">
                        <div class="author">Posted by<a href="#"><i class="fas fa-user"></i>Jonas Doe</a></div>
                        <div class="comments"><a href="blog-single.html"><i class="fas fa-comment"></i>5</a></div>
                      
                        <div class="text-center col-md-12">
                           <a href="blog-single.html" class="btn btn-primary ">Read more</a>
                        </div>
                     </div>
                    
                  </div>
               
                  <div class="blog-box">
                   
                     <a href="blog-single.html">
                        <div class="image"><img src="	https://www.ingridkuhn.com/themes/abctots/img/blog/blogstyle2-3.jpg" alt=""/></div>
                     </a>
                  
                     <div class="blog-box-caption">
                       
                        <div class="date"><span class="day">02</span><span class="month">July</span></div>
                        <a href="blog-single.html">
                           <h4>20 Fun Activities to Do With Your Kids</h4>
                        </a>
                      
                        <p>
                           Donec commodo sodales ex, scelerisque laoreet nibh hendrerit id. In aliquet magna nec lobortis...
                        </p>
                     </div>
                    
                     <div class="blog-box-footer">
                        <div class="author">Posted by<a href="#"><i class="fas fa-user"></i>Lauren Smith</a></div>
                        <div class="comments"><a href="blog-single.html"><i class="fas fa-comment"></i>10</a></div>
                      
                        <div class="text-center col-md-12">
                           <a href="blog-single.html" class="btn btn-primary ">Read more</a>
                        </div>
                     </div>
                  
                  </div>
                
                  <div class="blog-box">
                   
                     <a href="blog-single.html">
                        <div class="image"><img src="	https://www.ingridkuhn.com/themes/abctots/img/blog/blogstyle2-4.jpg" alt=""/></div>
                     </a>
                   
                     <div class="blog-box-caption">
                      
                        <div class="date"><span class="day">18</span><span class="month">June</span></div>
                        <a href="blog-single.html">
                           <h4>Brain-Boosting Activities for Children</h4>
                        </a>
                      
                        <p>
                           Donec commodo sodales ex, scelerisque laoreet nibh hendrerit id. In aliquet magna nec lobortis...
                        </p>
                     </div>
                  
                     <div class="blog-box-footer">
                        <div class="author">Posted by<a href="#"><i class="fas fa-user"></i>Jonas Doe</a></div>
                        <div class="comments"><a href="blog-single.html"><i class="fas fa-comment"></i>11</a></div>
                        
                        <div class="text-center col-md-12">
                           <a href="blog-single.html" class="btn btn-primary ">Read more</a>
                        </div>
                     </div>
                    
                  </div>
                
               </div>
              
            </div>
         
         </section> */}
          
         <section id="callout" class=" container-fluid bg-fixed">
            <div class="container">
             
               <div class="row">
                  <div class="col-lg-6 p-0" data-start="right: 50%;" 
                     data-center="right:-5%;">
                  
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667389530/blossom/Enroll_Today.jpg" class="img-fluid img-rounded" alt=""/>
                  </div>
                 
                  <div class="col-lg-6 bg-secondary p-5 justify-content-center align-self-center"  data-start="left: 50%;" 
                     data-center="left:-5%;">
                     <div class="text-light justify-content-center align-self-center">
                        <h3>Enroll Today</h3>
                        <p>We are Open for the Intake of children from the age of three months to five years. This includes Infants, Toddlers, and preschool children. Our Offices are Open from 08:00 am to 5:00 pm from Mondays to Fridays.</p>
                        <a href="/contact" class="btn btn-tertiary">Contact us</a>
                     </div>
            
                  </div>
                 
               </div>
            
            </div>
         
         </section>
      
         <section id="contact-home" class="container">
            <div class="row">
               <div class="col-lg-10 offset-lg-1 text-center">
               
                  <div class="section-heading text-center">
                     <h2>Contact Us</h2>
                     <p class="subtitle">Get in Touch</p>
                  </div>
                
                  <div class="contact-info res-margin">
                     <div class="row res-margin">
                        <div class="col-lg-4">
                           <div class="contact-icon bg-secondary text-light">
                        
                              <i class="fa fa-envelope top-icon"></i>
                           
                              <div class="contact-icon-info">
                                 <h5>Write us</h5>
                                 <p><a href="mailto:blossomkidshub@gmail.com">blossomkidshub@gmail.com</a><br/><a href="mailto:Principal@blossomkidshub.co.tz">Principal@blossomkidshub.co.tz</a></p>
                      
                              </div>
                           </div>
                          
                        </div>
   
                        <div class="col-lg-4 res-margin">
                           <div class="contact-icon bg-secondary text-light">
                     
                              <i class="fa fa-map-marker top-icon"></i>
                            
                              <div class="contact-icon-info">
                                 <h5>Visit us</h5>
                                 <p>Mbezi Beach Makonde, <br/>Dar es Salaam, Tanzania</p>
                              </div>
                           </div>
                  
                        </div>
                      
                        <div class="col-lg-4 res-margin">
                           <div class="contact-icon bg-secondary text-light">
                       
                              <i class="fa fa-phone top-icon"></i>
                          
                              <div class="contact-icon-info">
                                 <h5>Call us</h5> 
                                 <p>+255 764 961 591 <br/>+255 656 819 944</p>
                       
                              </div>
                           </div>
                      
                        </div>
              
                     </div>
                   
                  </div>
               
               </div>
            
               {/* <div class="col-lg-12 mt-5 block-padding force notepad pl-5 pr-5" >
                  <div class="row">
                     <div class="col-lg-7">
            
                        <h4>Send us a message</h4>
                     
                        <div id="contact_form">
                           <div class="form-group">
                              <div class="row">
                                 <div class="col-md-6">
                                    <label>Name<span class="required">*</span></label>
                                    <input type="text" name="name" class="form-control input-field" required=""/> 
                                 </div>
                                 <div class="col-md-6">
                                    <label>Email Address <span class="required">*</span></label>
                                    <input type="email" name="email" class="form-control input-field" required=""/> 
                                 </div>
                              </div>
                              <div class="row">
                                 <div class="col-md-12">
                                    <label>Subject</label>
                                    <input type="text" name="subject" class="form-control input-field"/> 
                                 </div>
                                 <div class="col-md-12">
                                    <label>Message<span class="required">*</span></label>
                                    <textarea name="message" id="message" class="textarea-field form-control" rows="3"  required=""></textarea>
                                 </div>
                              </div>
                              <button type="submit" id="submit_btn" value="Submit" class="btn btn-tertiary">Send message</button>
                           </div>
                         
                           <div id="contact_results"></div>
                        </div>
                 
                     </div>
                  
                     <div class="col-lg-5">
                 
                        <div id="map-canvas" class="mt-5 rounded"></div>
                     </div>
                  
                     <div class="ornament-stars mt-8" data-aos="zoom-out"></div>
                  </div>
            
               </div> */}
             
            </div>
       
         </section>
    
      </div>
    
   </Layout>
  );
}

export default Homepage;