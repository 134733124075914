import React from 'react';
import Layout from '../layout';
import { Helmet } from 'react-helmet';





function Services() {
  return (
<Layout page='home'>
<Helmet>
        <title>Blossom Kids Hub | Services</title>
      </Helmet>
    
      <div id="page-wrapper">

         <div class=" jumbotron programs jumbotron-fluid">
            <div class="container" >
      
               <div class="jumbo-heading">
                  <h1>Programs</h1>
               
                  <nav aria-label="breadcrumb">
                     <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Programs</li>
                     </ol>
                  </nav>
               
               </div>
             
            </div>
     
         </div>
        
         <div class="page">
            <div class="container block-padding">
         
               <div class="row">
                  <div class="col-lg-5">
                     <h2>Quality Services</h2>
                     <span class="h7 mt-3">OUR DAYCARE OFFERS A SAFE AND HEALTHY LEARNING ENVIRONMENT FOR ALL CHILDREN</span>
                  </div>
           
                  <div class="col-lg-7">
                     <p class="mt-2">
                     Our Classrooms are vibrant, clean, safe, fun spaces for your child. We have a daycare room, toddler’s classroom, preschool classroom, and kindergarten classroom. Each class has a dedicated classroom that is filled with age-appropriate learning materials for children to explore. Not only that, we have the best early childhood playgrounds in our area.
                     </p>
                  </div>
                
               </div>
       
               <div class="mt-5">
               
                  <div class="row vertical-tabs">
                     <div class="col-lg-12">
          
                        <div class="tabs-with-icon">
                           <nav>
                              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                 <a class="nav-item nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1"><i class="flaticon-abc-block"></i>Daycare</a>
                                 <a class="nav-item nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" ><i class="flaticon-kids-1"></i>Kindergarten</a>
                                 <a class="nav-item nav-link" id="tab3-tab" data-toggle="tab" href="#tab3" ><i class="flaticon-smiling-baby"></i>Infant care</a>
                                 <a class="nav-item nav-link" id="tab4-tab" data-toggle="tab" href="#tab4"><i class="flaticon-blackboard"></i>Garden</a>
                                 <a class="nav-item nav-link" id="tab5-tab" data-toggle="tab" href="#tab5" ><i class="flaticon-kids"></i>Special Services</a>
                              </div>
                           </nav>
                  
                           <div class="tab-content block-padding bg-light" id="nav-tabContent">
                              <div class="tab-pane active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                               
                                 <div class="row">
                                    <div class="col-lg-6">
                              
                                       <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667389175/blossom/Our_services_Daycare.jpg" alt="" class="rounded img-fluid"/>
                                      
                                       <div class="ornament-rainbow" data-aos="fade-right"></div>
                                    </div>
                           
                                    <div class="col-lg-6">
                                       <h3>Daycare</h3>
                                       <p>Our daycare programs intake kids from the age of 6 months to 5 Years. Our Nurturing Environment and Professionals will offer Personal Assistance to every child and help with the Development of Hobbies, Creative Skills and Guide everything for</p>
                                       <ul class="custom pl-0">
                                          <li>Infants (6 months to 1 year )</li>
                                          <li>Toddlers (2 years to 3 years)</li>
                                          <li>Preschool kids (3 years to 5 years)</li>
                                       </ul>
                                     
                                       <a href="/gallery" class="btn btn-secondary mt-4">See More</a>
                                    </div>
                       
                                 </div>
                                
                              </div>
                        
                              <div class="tab-pane" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                                 <div class="row">
                                    <div class="col-lg-6">
                                       <h3>Kindergarten</h3>
                                       <p>Our kindergarten and preschool program is the perfect way to support your child’s school readiness in an exciting, engaging way. Our educational approach is based on studying, playing, singing, practical activities such as drawing, and social interaction</p>
                                       {/* <ul class="custom pl-0">
                                          <li>Over 30 Qualified professionals</li>
                                          <li>We offer you our quality services since 2002</li>
                                          <li>Educational activities in our daily plan</li>
                                       </ul>
                               
                                       <a href="/services-single" class="btn btn-secondary mt-4">Read More</a> */}
                                    </div>
                                 
                                    <div class="col-lg-6 res-margin">
                               
                                       <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368476/blossom/Our_programs_kindergarten.jpg" alt="" class="rounded img-fluid"/>
                                     
                                       <div class="ornament-stars" data-aos="fade-right"></div>
                                    </div>
                                 
                                 </div>
                               
                              </div>
                           
                              <div class="tab-pane" id="tab3" role="tabpanel" aria-labelledby="tab3-tab">
                                 <div class="row">
                                    <div class="col-lg-6">
                         
                                       <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368476/blossom/Our_programs_infant_care.jpg" alt="" class="rounded img-fluid"/>
                               
                                       <div class="ornament-bubbles" data-aos="fade-right"></div>
                                    </div>
                                  
                                    <div class="col-lg-6">
                                       <h3>Infant Care</h3>
                                       <p>Blossom Kids Hub will provide your infant with high-quality care services from our professional caregivers. Infants need intense care and so, we are the best choice for that.</p>
                                       {/* <ul class="custom pl-0">
                                          <li>Over 30 Qualified professionals</li>
                                          <li>We offer you our quality services since 2002</li>
                                          <li>Educational activities in our daily plan</li>
                                       </ul>
                                    
                                       <a href="/services-single" class="btn btn-secondary mt-4">Read More</a> */}
                                    </div>
                                  
                                 </div>
                        
                              </div>
                           
                              <div class="tab-pane" id="tab4" role="tabpanel" aria-labelledby="tab4-tab">
                                 <div class="row">
                                    <div class="col-lg-6">
                                       <h3>Garden</h3>
                                       <p>Garden activities will nourish Child’s curiosity with Wonder. Garden activities will also provide Fun games, Refreshments, Resting beds, and Healthy Meals.</p>
                                       {/* <ul class="custom pl-0">
                                          <li>Over 30 Qualified professionals</li>
                                          <li>We offer you our quality services since 2002</li>
                                          <li>Educational activities in our daily plan</li>
                                       </ul>
                                   
                                       <a href="services-single.html" class="btn btn-secondary mt-4">Read More</a> */}
                                    </div>
                                 
                                    <div class="col-lg-6 res-margin">
                            
                                       <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368476/blossom/Our_programs_garden.jpg" alt="" class="rounded img-fluid"/>
                                     
                                       <div class="ornament-stars" data-aos="fade-right"></div>
                                    </div>
                                   
                                 </div>
                               
                              </div>
                        
                              <div class="tab-pane" id="tab5" role="tabpanel" aria-labelledby="tab5-tab">
                                 <div class="row">
                                    <div class="col-lg-6">
                                     
                                       <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368477/blossom/Our_programs_special_services.jpg" alt="" class="rounded img-fluid"/>
                                 
                                       <div class="ornament-rainbow" data-aos="fade-right"></div>
                                    </div>
                                 
                                    <div class="col-lg-6">
                                       <h3>Special Services</h3>
                                       <p>Our Special Services include Camps and Vacations which are very important for kids to develop critical social, mental, and emotional skills.</p>
                                       {/* <ul class="custom pl-0">
                                          <li>Over 30 Qualified professionals</li>
                                          <li>We offer you our quality services since 2002</li>
                                          <li>Educational activities in our daily plan</li>
                                       </ul>
                                     
                                       <a href="services-single.html" class="btn btn-secondary mt-4">Read More</a> */}
                                    </div>
                                   
                                 </div>
                               
                              </div>
                            
                           </div>
                       
                        </div>
                    
                     </div>
                    
                  </div>
                
               </div>
             
            </div>
          
         </div>
        
      </div>
   
   
    
   </Layout>
  );
}

export default Services;