import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Layout = (props) => {
   const {pathname} = useLocation();
    let $ = window?.jQuery;      
         useEffect(() => {
            $("#preloader").fadeOut("slow");
            window?.AOS?.init({
               // disable: 'mobile',
               duration: 1500,
               once: true
            });

            window?.skrollr?.init({
               forceHeight: false
            });

            // disable skrollr if using handheld device
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				window?.skrollr?.init().destroy();
			}
            $(window).on("resize", function () {
               var headerHeight = $('#main-nav').outerHeight();
              $('#page-wrapper').css('padding-top', headerHeight);
        }).resize();
            $('#slider').layerSlider({
                responsive: true,
                fitScreenWidth:false,
                responsiveUnder: 1280,
                layersContainer: 1280,
                skin: 'outline',
                hoverPrevNext: true,
                skinsPath: './vendor/layerslider/skins/',
                autoStart: true,
                autoPlayVideos : false,
                showCircleTimer: false
            });
            $('.owl-stage').owlCarousel({
               loop: true,
               margin: 0,
               autoplayHoverPause:true,
               autoplay: true,
               nav: true,
               navText: [" <i class='fas fa-chevron-left'></i>", " <i class='fas fa-chevron-right'></i>"],
               dots: true,
               responsive: {
                  0: {
                     items: 1,
                     stagePadding: 0
                  },
                  767: {
                     items: 2,
                     stagePadding: 60
                  },
                  1200: {
                     items: 3,
                     stagePadding: 120
                  },
               }
            });
            
            $(".carousel-4items").owlCarousel({
               nav: true,
               navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
               dots: true,
               margin: 30,
               loop: true,
               autoplay: false,
               responsiveClass: true,
               responsive: {
                  0: {
                     items: 1,
                  },
                  767: {
                     items: 2,
                  },
                  1200: {
                     items: 4,
                  },
               }
            });
            $(".carousel-3items").owlCarousel({
               nav: true,
               navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
               dots: true,
               margin: 30,
               loop: true,
               autoplay: false,
               responsiveClass: true,
               responsive: {
                  0: {
                     items: 1,
                  },
                  767: {
                     items: 2,
                  },
                  1200: {
                     items: 3,
                  },
               }
            });
            $(".carousel-2items").owlCarousel({
               nav: true,
               navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
               dots: true,
               margin: 30,
               loop: true,
               autoplay: false,
               responsiveClass: true,
               responsive: {
                  0: {
                     items: 1,
                  },
                  991: {
                     items: 2,
                  },
               }
            });
            $(".carousel-1item").owlCarousel({
               nav: true,
               navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
               dots: true,
               margin: 30,
               loop: true,
               autoplay: false,
               responsiveClass: true,
               responsive: {
                  0: {
                     items: 1,
                  },									
               }
            });
    
         }, [])

  return (
    <>
     <div id="preloader">
         <div class="container h-100">
            <div class="row h-100 justify-content-center align-items-center">
               <div class="preloader-logo">
                  <div class="spinner">
                     <div class="dot1"></div>
                     <div class="dot2"></div>
                  </div>
               </div>
            </div>
         </div>
      </div>
     <nav id="main-nav" class="navbar-expand-xl fixed-top">
         <div class="row">
            {/* <div class="container-fluid top-bar" >
               <div class="container">
                  <div class="row">
                     <div class="col-md-12">
                        <ul class="contact-details float-left">
                           <li><i class="fa fa-map-marker"></i>Mbezi Makonde Dar Es Salaam TZ, 14121</li>
                           <li><i class="fa fa-envelope"></i><a href="mailto:blossomkidshub@gmail.com">blossomkidshub@gmail.com</a></li>
                           <li><i class="fa fa-phone"></i>+255 764 961 591</li>
                        </ul>
                        <ul class="social-list float-right list-inline">
                           <li class="list-inline-item"><a title="Facebook" href="https://www.facebook.com/profile.php?id=100076005002742"><i class="fab fa-facebook-f"></i></a></li>
                           <li class="list-inline-item"><a title="Twitter" href="https://twitter.com/BlossomKidsHub"><i class="fab fa-twitter"></i></a></li>
                           <li class="list-inline-item"><a  title="Instagram" href="https://www.instagram.com/blossom_kids_hub/"><i class="fab fa-instagram"></i></a></li>
                           <li class="list-inline-item"><a  title="Linkedin" href=" https://www.linkedin.com/company/blossom-kids-hub/?viewAsMember=true"><i class="fab fa-linkedin"></i></a></li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div> */}
            <div class="navbar container-fluid">
               <div class="container ">
                  <a class="nav-brand" href="/">
                  <img src="img/blossom.png" alt="" class="img-fluid"/>
                  </a>
                  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggle-icon">
                  <i class="fas fa-bars"></i>
                  </span>
                  </button>
                  <div class="collapse navbar-collapse" id="navbarResponsive">
                     <ul class="navbar-nav ml-auto">
                        <li class={`nav-item ${pathname === '/' ? 'active' : null}`}>
                           <a class="nav-link" href="/">Home
                           </a>
                        </li>
                        <li class={`nav-item ${pathname === '/about' ? 'active' : null}`}>
                           <a class="nav-link" href="/about">About
                           </a>
                        </li>
                        <li class={`nav-item ${pathname === '/services' ? 'active' : null}`}>
                           <a class="nav-link" href="/services">Our Programs
                           </a>
                        </li>
               
                        <li class={`nav-item ${pathname === '/gallery' ? 'active' : null}`}>
                           <a class="nav-link" href="/gallery">Gallery
                           </a>
                        </li>
                        <li class={`nav-item ${pathname === '/contact' ? 'active' : null}`}>
                           <a class="nav-link" href="/contact">Contact Us
                           </a>
                        </li>
                        {/* <li class="nav-item dropdown">
                           <a class="nav-link dropdown-toggle" href="#" id="others-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                           Other pages
                           </a>
                           <div class="dropdown-menu" aria-labelledby="others-dropdown">
                              <a class="dropdown-item" href="/blog">Blog Home 1</a>
                              <a class="dropdown-item" href="/blog2">Blog Home 2</a>
                              <a class="dropdown-item" href="/blog-single">Blog Single</a>
                              <a class="dropdown-item" href="/elements">Elements Page</a>
                              <a class="dropdown-item" href="/404">404 Page</a>
                           </div>
                        </li> */}
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </nav>
    {props.children}
    {/* <svg version="1.1" id="footer-divider"  class="secondary" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 1440 126" xml:space="preserve" preserveAspectRatio="none slice">
         <path class="st0" d="M685.6,38.8C418.7-11.1,170.2,9.9,0,30v96h1440V30C1252.7,52.2,1010,99.4,685.6,38.8z"/>
      </svg> */}
      <footer class="bg-secondary text-light">
         <div class="container">
            <div class="row">
               <div class="col-lg-4 text-center">
                  <img src="img/blossom.png"  class="logo-footer img-fluid" alt=""/>
                  <h5 class="mt-3">Every Milestone Counts</h5>		
                  <div id="mc_embed_signup">
                 
                                 <a href='/contact' class="btn btn-tertiary" value="Subscribe" name="subscribe" id="mc-embedded-subscribe">Enroll Today</a>
                               
                  </div>	  
               </div>
               <div class="col-lg-4 text-center res-margin">
                  <h5>Contact Us</h5>
                  <ul class="list-unstyled mt-3">
                     <li class="mb-1"><i class="fas fa-phone margin-icon "></i>+255 764 961 591</li>
                     <li class="mb-1"><i class="fas fa-envelope margin-icon"></i><a href="mailto:blossomkidshub@gmail.com">blossomkidshub@gmail.com</a></li>
                     <li><i class="fas fa-map-marker margin-icon"></i>Mbezi Beach Makonde,<br/>Dar es Salaam, Tanzania</li>
                  </ul>
                  <ul class="social-list text-center list-inline mt-2">
                     <li class="list-inline-item"><a target={'_blank'} title="Facebook" href="https://www.facebook.com/profile.php?id=100076005002742"><i class="fab fa-facebook-f"></i></a></li>
                     <li class="list-inline-item"><a target={'_blank'} title="Twitter" href="https://twitter.com/BlossomKidsHub"><i class="fab fa-twitter"></i></a></li>
                     <li class="list-inline-item"><a target={'_blank'}  title="Instagram" href="https://www.instagram.com/blossom_kids_hub/"><i class="fab fa-instagram"></i></a></li>
                     <li class="list-inline-item"><a target={'_blank'}  title="Linkedin" href="https://www.linkedin.com/company/blossom-kids-hub/?viewAsMember=true"><i class="fab fa-linkedin"></i></a></li>
                  </ul>
               </div>
               <div class="col-lg-4 text-center">
                  <h5>Working Hours</h5>
                  <ul class="list-unstyled mt-3">
                     <li class="mb-1">Monday to Saturday</li>
                     <li class="mb-1">Open from 8.00am - 5.00p.m</li>
                     <li class="mb-1">Sunday Closed</li>
                     <li>Holidays Closed</li>
                  </ul>
               </div>
            </div>
            <hr/>
            <div class="row">
               <div class="credits col-sm-12">
                  <p>©️ Copyright {new Date().getFullYear()} | Designed by <a  target={'_blank'} href="https://www.essence.co.tz">Essence Creative</a></p>
               </div>
            </div>
         </div>
         <div class="page-scroll hidden-sm hidden-xs">
            <a href="#top" class="back-to-top"><i class="fa fa-angle-up"></i></a>
         </div>
      </footer>
    </>
    
  )
}

export default Layout;