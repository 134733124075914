import React, {useState, useRef} from 'react';
import Layout from '../layout';
import emailjs from "@emailjs/browser";
import { Helmet } from 'react-helmet';





function Contact() {
   const form = useRef();
   const [loading, setLoading] = useState(false);
   const $ = window?.jQuery;

   const sendEmail = (e) => {
      e.preventDefault();
      setLoading(true);
      emailjs.sendForm("service_i435z6d", "template_npke678", form.current, "bbK5pOkSJnWywEMlf").then(
        (result) => {
            setLoading(false);
            $('#contactmodal').modal({
               show: true
            })
        },
        (error) => {
            setLoading(false);
          console.log(error.text);
        }
      );
    };
  return (
<Layout page='home'>
<Helmet>
        <title>Blossom Kids Hub | Contact</title>
      </Helmet>
      <div id="page-wrapper">
    
         <div class="jumbotron contactus jumbotron-fluid">
            <div class="container" >
          
               <div class="jumbo-heading">
                  <h1>Contact</h1>
              
                  <nav aria-label="breadcrumb">
                     <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Contact</li>
                     </ol>
                  </nav>
            
               </div>
        
            </div>
      
         </div>
     
         <div class="page container">
          
               <div class="row">
             
               <div class="col-lg-12 page-with-sidebar">
                  <div class="col-md-12">
                     <div class="row">
                    
                        <div class="contact-info col-lg-12">
                     
                           <h3 class="mb-2">STUDENT REGISTRATION FORM </h3>
                           {/* <p>Mei te apeirian omittantur reformidans, duo in appetere interesset concludaturque In id fermentum augue cenas at arcu risus lorem onec com modo sodales e
                              Est eruditi erroribus liberavisse in.
                           </p> */}
                           {/* <h4 class="mt-5">Register Now</h4> */}
                           <h6 class="mt-5">Section A:  Parent /  Guardian Details</h6>
                           <form ref={form} onSubmit={sendEmail} class="needs-validation" novalidate>
                           <div id="contact_form">
                              <div class="form-group">
                                 <div class="row">
                                    <div class="col-md-6">
                                       <label for="validationCustom01">Parent Name<span class="required">*</span></label>
                                       <input type="text" id='validationCustom01' name="from_name" class="form-control input-field" required/> 
                                    </div>
                                    <div class="col-md-6">
                                       <label>Phone Number<span class="required">*</span></label>
                                       <input type="text" name="parent_phone" class="form-control input-field" required/> 
                                    </div>
                                 </div>
                                 <div class="row">
                                    <div class="col-md-6">
                                       <label>Email Address <span class="required">*</span></label>
                                       <input type="email" name="parent_email" class="form-control input-field" required/> 
                                    </div>
                                    <div class="col-md-6">
                                       <label>Resident Location<span class="required">*</span></label>
                                       <input type="text" name="resident_location" class="form-control input-field" required/> 
                                    </div>
                                 </div>
                                 <h6 class="mt-5">Section B: Student Details</h6>
                                 <div class="row">
                                 <div class="col-md-6">
                                       <label>Student Name<span class="required">*</span></label>
                                       <input type="text" name="student_name" class="form-control input-field" required/> 
                                    </div>
                                    <div class="col-md-6">
                                       <label>Age<span class="required">*</span></label>
                                       <input type="text" name="age" class="form-control input-field" required/> 
                                    </div>
                                 </div>
                                 <div class="row">
                                    <div class="col-md-6">
                                    <label for="exampleFormControlSelect1">Gender</label>
                                    <select required class="form-control selectinput" name='gender' id="exampleFormControlSelect1">
                                       <option>Male</option>
                                       <option>Female</option>
                                    </select>
                                    </div>
                                    <div class="col-md-6">
                                       <label>Nationality<span class="required">*</span></label>
                                       <input type="text" name="nationality" class="form-control input-field" required/> 
                                    </div>
                                 </div>
                                 <div class="row">
                                    <div class="col-md-12">
                                    <label for="exampleFormControlSelect1">Admission sought for:</label><br/> 
                                    <div class="form-check form-check-inline">
  <input class="form-check-input" name='play_group' type="checkbox" id="inlineCheckbox1" value="Playgroup,"/>
  <label class="form-check-label" for="inlineCheckbox1">Play Group</label>
</div>
<div class="form-check form-check-inline">
  <input class="form-check-input" name='nursery' type="checkbox" id="inlineCheckbox2" value="Nursery,"/>
  <label class="form-check-label" for="inlineCheckbox2">Nursery</label>
</div>
<div class="form-check form-check-inline">
  <input class="form-check-input" name='reception_class' type="checkbox" id="inlineCheckbox2" value="Reception class,"/>
  <label class="form-check-label" for="inlineCheckbox2">Reception Class</label>
</div>
<div class="form-check form-check-inline">
  <input class="form-check-input" name='daycare' type="checkbox" id="inlineCheckbox2" value="Day care,"/>
  <label class="form-check-label" for="inlineCheckbox2">Day Care</label>
</div>
<div class="form-check form-check-inline">
  <input class="form-check-input" name='after_school' type="checkbox" id="inlineCheckbox2" value="After school"/>
  <label class="form-check-label" for="inlineCheckbox2">After School</label>
</div>
                                    </div>
                                 </div>
                                 <div class="row">
                                    <div class="col-md-12">
                                       <label>Message<span class="required">*</span></label>
                                       <textarea name="message" id="message" class="textarea-field form-control" rows="3"  required></textarea>
                                    </div>
                                 </div>
                                 <button type="submit" id="submit_btn" value="Submit" class="btn btn-primary mt-5">{loading ? 'submitting...' : 'Submit'}</button>
                              </div>
                             
                              <div id="contact_results"></div>
                           </div>
                           </form>
                           <div class="modal" id='contactmodal' tabindex="-1" role="dialog">
                              <div class="modal-dialog" role="document">
                                 <div class="modal-content">
                                    <div class="modal-header">
                                    <h5 class="modal-title">Congratulations!</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                       <span aria-hidden="true">&times;</span>
                                    </button>
                                    </div>
                                    <div class="modal-body">
                                    <p>Your request has been successfully submitted.<br/>
We will get in touch with you as soon as possible</p>
                                    </div>
                                    <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                    </div>
                                 </div>
                              </div>
                              </div>
                        </div>
                
                        <div class="col-lg-12">
                          
                           <div class="contact-info res-margin">
                              <div class="row res-margin mt-5 res-margin">
                                 <div class="col-lg-4 mt-5">
                                    <div class="contact-icon-two bg-secondary text-light">
                                       
                                       <i class="fa fa-envelope top-icon"></i>
                                     
                                       <div class="contact-icon-info res-margin-contact">
                                          <h5>Email us</h5>
                                          <p><a href="mailto:blossomkidshub@gmail.com">blossomkidshub@gmail.com</a><br/><a href="mailto:Principal@blossomkidshub.co.tz">Principal@blossomkidshub.co.tz</a></p>
                                  
                                       </div>
                                    </div>
                             
                                 </div>
                              
                                 <div class="col-lg-4 mt-5 res-margin">
                                    <div class="contact-icon-two bg-secondary text-light">
                               
                                       <i class="fa fa-map-marker top-icon"></i>
                                    
                                       <div class="contact-icon-info res-margin-contact">
                                          <h5>Visit us</h5>
                                          <p>Mbezi Beach Makonde, <br/>Dar es Salaam, Tanzania</p>
                                       </div>
                                    </div>
                            
                                 </div>
                      
                                 <div class="col-lg-4 mt-5 res-margin">
                                    <div class="contact-icon-two bg-secondary text-light">
                                       
                                       <i class="fa fa-phone top-icon"></i>
                                    
                                       <div class="contact-icon-info res-margin-contact">
                                          <h5>Call us</h5>
                                          <p>+255 764 961 591 <br/>+255 656 819 944</p>
                                       </div>
                                    </div>
                               
                                 </div>
                          
                              </div>
                    
                           </div>
                        
                        </div>
                     
                     </div>
                     <div class="mt-5 map-small-height container">
                     <div class="mapouter"><div class="gmap_canvas"><iframe style={{width: '100%', height: 300}} id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15849.83510084902!2d39.2112334!3d-6.7137319!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185c51593edbff7f%3A0x95fbe381114da46c!2sBlossom%20Kids%20Hub%20-%20Tanzania!5e0!3m2!1sen!2stz!4v1668601928008!5m2!1sen!2stz" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe></div></div>
                     </div>
                  </div>
          
               </div>
            </div>
            
          
         </div>
       
      </div>

   

   
    
   </Layout>
  );
}

export default Contact;