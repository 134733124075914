import React from 'react'
import { Switch, Route } from 'react-router-dom'
import First from './components/homepage';
import Services from './components/services';
import Services2 from './components/services2';
import Servicessingle from './components/services-single';
import About from './components/about';
import Ourteam from './components/ourteam';
import Gallery from './components/gallery';
import Contact from './components/contact';
import About2 from './components/about2';
import Contact2 from './components/contact2';
function App() {
  return (
    <Switch>
    <Route exact path='/'>
    <First />
    </Route>
    <Route exact path='/services'>
    <Services2 />
    </Route>
    <Route exact path='/about'>
    <About2 />
    </Route>
    <Route exact path='/gallery'>
    <Gallery />
    </Route>
    <Route exact path='/contact'>
    <Contact />
    </Route>
</Switch>
  );
}

export default App;
