import React from 'react';
import Layout from '../layout';
import { Helmet } from 'react-helmet';





function Gallery() {
  return (
<Layout page='home'>
<Helmet>
        <title>Blossom Kids Hub | Gallery</title>
      </Helmet>
      <div id="page-wrapper">
 
         <div class="jumbotron gallerypage jumbotron-fluid">
            <div class="container" >
               <div class="jumbo-heading">
                  <h1>Gallery</h1>
               
                  <nav aria-label="breadcrumb">
                     <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Gallery </li>
                     </ol>
                  </nav>
               
               </div>
           
            </div>
           
         </div>
         <div class="page">
            <div class="container">
               <div class="col-lg-6 text-center offset-lg-3">
                  <h2>Our Photo Gallery</h2>
                  <p>Let’s Create More Confident and Creative Little Souls.</p>
               </div>
            
            </div>
        
            <div class="container magnific-popup mt-5">

            <div class="row">
                  
                  <div class="col-lg-3">
                     <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1667368475/blossom/our_photo_gallery_in_GALLERY_TAB_0001_693A6040.jpg" title="You can add caption to pictures.">
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368475/blossom/our_photo_gallery_in_GALLERY_TAB_0001_693A6040.jpg" class="blob img-fluid" alt=""/>
                     </a>
                  </div>
                  
                  <div class="col-lg-3">
                     <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1667368474/blossom/our_photo_gallery_in_GALLERY_TAB_0002_693A6003.jpg" title="You can add caption to pictures.">
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368474/blossom/our_photo_gallery_in_GALLERY_TAB_0002_693A6003.jpg" class="blob2 img-fluid" alt=""/>
                     </a>
                  </div>
                  
                  <div class="col-lg-3">
                     <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1667368475/blossom/our_photo_gallery_in_GALLERY_TAB_0003_693A6208.jpg" title="You can add caption to pictures.">
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368475/blossom/our_photo_gallery_in_GALLERY_TAB_0003_693A6208.jpg" class="blob img-fluid" alt=""/>
                     </a>
                  </div>
                  
                  <div class="col-lg-3">
                     <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1667368475/blossom/our_photo_gallery_in_GALLERY_TAB_0004_693A6031.jpg" title="You can add caption to pictures.">
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368475/blossom/our_photo_gallery_in_GALLERY_TAB_0004_693A6031.jpg" class="blob2 img-fluid" alt=""/>
                     </a>
                  </div>
               </div>
               
               
               <div class="row mt-4">
                  
                  <div class="col-lg-3">
                     <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1667368475/blossom/our_photo_gallery_in_GALLERY_TAB_0005_693A5754.jpg" title="You can add caption to pictures.">
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368475/blossom/our_photo_gallery_in_GALLERY_TAB_0005_693A5754.jpg" class="blob2 img-fluid" alt=""/>
                     </a>
                  </div>
                  
                  <div class="col-lg-3">
                     <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1667368475/blossom/our_photo_gallery_in_GALLERY_TAB_0006_693A6149.jpg" title="You can add caption to pictures.">
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368475/blossom/our_photo_gallery_in_GALLERY_TAB_0006_693A6149.jpg" class="blob img-fluid" alt=""/>
                     </a>
                  </div>
                  
                  <div class="col-lg-3">
                     <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1667368476/blossom/our_photo_gallery_in_GALLERY_TAB_0007_693A5773.jpg" title="You can add caption to pictures.">
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368476/blossom/our_photo_gallery_in_GALLERY_TAB_0007_693A5773.jpg" class="blob2 img-fluid" alt=""/>
                     </a>
                  </div>
                  
                  <div class="col-lg-3">
                     <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1667368476/blossom/our_photo_gallery_in_GALLERY_TAB_0008_693A5492.jpg" title="You can add caption to pictures.">
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368476/blossom/our_photo_gallery_in_GALLERY_TAB_0008_693A5492.jpg" class="blob img-fluid" alt=""/>
                     </a>
                  </div>
               </div>
               
               
               <div class="row mt-4">
                  
                  <div class="col-lg-3">
                     <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1667368476/blossom/our_photo_gallery_in_GALLERY_TAB_0009_693A6091.jpg" title="You can add caption to pictures.">
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368476/blossom/our_photo_gallery_in_GALLERY_TAB_0009_693A6091.jpg" class="blob img-fluid" alt=""/>
                     </a>
                  </div>
                
                  <div class="col-lg-3">
                     <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1667368476/blossom/our_photo_gallery_in_GALLERY_TAB_0010_693A5639.jpg" title="You can add caption to pictures.">
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368476/blossom/our_photo_gallery_in_GALLERY_TAB_0010_693A5639.jpg" class="blob2 img-fluid" alt=""/>
                     </a>
                  </div>
              
                  <div class="col-lg-3">
                     <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1667368476/blossom/our_photo_gallery_in_GALLERY_TAB_0011_693A5753.jpg" title="You can add caption to pictures.">
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368476/blossom/our_photo_gallery_in_GALLERY_TAB_0011_693A5753.jpg" class="blob img-fluid" alt=""/>
                     </a>
                  </div>
        
                  <div class="col-lg-3">
                     <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1667368474/blossom/our_photo_gallery_in_GALLERY_TAB_0000_693A5707.jpg" title="You can add caption to pictures.">
                     <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1667368474/blossom/our_photo_gallery_in_GALLERY_TAB_0000_693A5707.jpg" class="blob2 img-fluid" alt=""/>
                     </a>
                  </div>
               </div>
               
            
            </div>
         
         </div>
       
      
      </div>
   

   
    
   </Layout>
  );
}

export default Gallery;